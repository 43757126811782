import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { formatCurrency } from '../utils/formatCurrency';
import { getClaimsAnalysisInfo, getClaimsAnalysisFilterValues } from '../services/api';

const DmrAnalysis = () => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [claimsAnalysisInfo, setClaimsAnalysisInfo] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [allDoctors, setAllDoctors] = useState([]);
  const [allPractices, setAllPractices] = useState([]);
  const [cptAnalysis, setCptAnalysis] = useState([]);
  const [dosAnalysis, setDosAnalysis] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedPractice, setSelectedPractice] = useState('');

  const handleDoctorChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  const handlePracticeChange = (event) => {
    setSelectedPractice(event.target.value);
  };

  const handleReset = () => {
    setSelectedPractice('');
    setSelectedDoctor('');
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (newValue) => {
    const formattedStartDate = dayjs(newValue).format('YYYY-MM-DD');
    setStartDate(formattedStartDate);
    if (endDate && newValue && dayjs(endDate).isBefore(newValue)) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (newValue) => {
    const formattedEndDate = dayjs(newValue).format('YYYY-MM-DD');
    setEndDate(formattedEndDate);
  };

  const getClaimsInfo = async () => {
    setLoading(true);
    try {
      const response = await getClaimsAnalysisInfo(startDate, endDate, selectedDoctor, selectedPractice);
      setClaimsAnalysisInfo(response?.data?.info?.count);
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
    setLoading(false);
  };

  const getFilterValues = async () => {
    setFilterLoading(true);
    try {
      const response = await getClaimsAnalysisFilterValues();
      setAllDoctors(response?.data?.info?.doctor_names);
      setAllPractices(response?.data?.info?.practice_names);
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
    setFilterLoading(false);
  };
  useEffect(() => {
    getFilterValues();
  }, []);

  useEffect(() => {
    getClaimsInfo();
  }, [startDate, endDate, selectedDoctor, selectedPractice]);
  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | DMR Analysis</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom>
              DMR Analysis
            </Typography>
          </Box>

          <Stack direction={'row'} alignItems={'center'} gap={6} mb={2} paddingX={2}>
            <Box display="flex" sx={{ width: '100%' }} gap={4} alignItems={'center'}>
              <Box sx={{ width: '250px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ marginBottom: 2 }}
                    label="Start Date"
                    value={startDate ? dayjs(startDate) : null}
                    maxDate={dayjs()}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <DatePicker
                    label="End Date"
                    value={endDate ? dayjs(endDate) : null}
                    minDate={startDate ? dayjs(startDate) : null}
                    maxDate={dayjs()}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>

              <Box minWidth={'180px'}>
                <FormControl fullWidth>
                  <InputLabel id="doctor-label">Doctor</InputLabel>
                  <Select
                    labelId="doctor-label"
                    id="doctor-select"
                    value={selectedDoctor}
                    label="Doctor"
                    onChange={handleDoctorChange}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 300 } },
                    }}
                  >
                    {filterLoading ? (
                      <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={20} />
                          <Typography sx={{ marginLeft: 1 }}>Loading...</Typography>
                        </Box>
                      </MenuItem>
                    ) : (
                      allDoctors.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>

              <Box minWidth={'180px'}>
                <FormControl fullWidth>
                  <InputLabel id="practice-label">Practice</InputLabel>
                  <Select
                    labelId="practice-label"
                    id="practice-select"
                    value={selectedPractice}
                    label="Practice"
                    onChange={handlePracticeChange}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 300 } },
                    }}
                  >
                    {filterLoading ? (
                      <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={20} />
                          <Typography sx={{ marginLeft: 1 }}>Loading...</Typography>
                        </Box>
                      </MenuItem>
                    ) : (
                      allPractices.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>

              <Button variant="text" startIcon={<ReplayIcon />} onClick={handleReset}>
                Reset
              </Button>
            </Box>
          </Stack>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Insurance Type{' '}
                        {claimsAnalysisInfo !== undefined ? `(${claimsAnalysisInfo?.insurance_types?.total})` : ''}
                      </Typography>
                      <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Insurance</TableCell>
                              <TableCell>Count</TableCell>
                              <TableCell>Charges</TableCell>
                              <TableCell>Payment</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {claimsAnalysisInfo?.insurance_types?.counts.length > 0 ? (
                              claimsAnalysisInfo?.insurance_types.counts.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{row.insurance_type}</TableCell>
                                  <TableCell>{row.claim_count}</TableCell>
                                  <TableCell>{formatCurrency(row.charges)}</TableCell>
                                  <TableCell>{formatCurrency(row.payments)}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Claims Status
                      </Typography>
                      <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Status</TableCell>
                              <TableCell>Count</TableCell>
                              <TableCell>Charges</TableCell>
                              <TableCell>Payment</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {claimsAnalysisInfo?.claim_status?.counts.length > 0 ? (
                              claimsAnalysisInfo?.claim_status.counts.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{row.claim_status}</TableCell>
                                  <TableCell>{row.claim_count}</TableCell>
                                  <TableCell>{formatCurrency(row.charges)}</TableCell>
                                  <TableCell>{formatCurrency(row.payments)}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Month Wise Analysis
                      </Typography>
                      <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Month</TableCell>
                              <TableCell>Year</TableCell>
                              <TableCell>Count</TableCell>
                              <TableCell>Charges</TableCell>
                              <TableCell>Payment</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {claimsAnalysisInfo?.date_of_service?.counts.length > 0 ? (
                              claimsAnalysisInfo?.date_of_service.counts.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{row.month}</TableCell>
                                  <TableCell>{row.year}</TableCell>
                                  <TableCell>{row.claim_count}</TableCell>
                                  <TableCell>{formatCurrency(row.charges)}</TableCell>
                                  <TableCell>{formatCurrency(row.payments)}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Payers
                      </Typography>
                      <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Practice Name</TableCell>
                              <TableCell>Count</TableCell>
                              <TableCell>Charges</TableCell>
                              <TableCell>Payment</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {claimsAnalysisInfo?.claim_primary_payer?.counts.length > 0 ? (
                              claimsAnalysisInfo?.claim_primary_payer.counts.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{row.claim_primary_payer_name}</TableCell>
                                  <TableCell>{row.claim_count}</TableCell>
                                  <TableCell>{formatCurrency(row.charges)}</TableCell>
                                  <TableCell>{formatCurrency(row.payments)}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Practices
                      </Typography>
                      <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Practice Name</TableCell>
                              <TableCell>Count</TableCell>
                              <TableCell>Charges</TableCell>
                              <TableCell>Payment</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {claimsAnalysisInfo?.claim_practice?.counts.length > 0 ? (
                              claimsAnalysisInfo?.claim_practice.counts.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{row.practice_name}</TableCell>
                                  <TableCell>{row.claim_count}</TableCell>
                                  <TableCell>{formatCurrency(row.charges)}</TableCell>
                                  <TableCell>{formatCurrency(row.payments)}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={4}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DmrAnalysis;
