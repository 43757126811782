import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { getBackgroundTasks } from '../services/api';

export default function BackgroundTasks() {
  const [backgroundTasks, setBackgroundTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getTasks = async () => {
    setLoading(true);
    try {
      const response = await getBackgroundTasks();
      setBackgroundTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      toast.error('Unable to get Background tasks, try again later.');
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | Background Tasks</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Stack marginBottom={7} direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              Background Tasks
            </Typography>
          </Stack>

          <Card>
            <TableContainer sx={{ minWidth: 800, marginTop: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Task Name</TableCell>
                    <TableCell>Task Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Time Taken (secs)</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : backgroundTasks.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="body2">No Background Tasks found.</Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : (
                    backgroundTasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((task) => (
                      <TableRow key={task.id}>
                        <TableCell>{task.id}</TableCell>
                        <TableCell>{task.task_name}</TableCell>
                        <TableCell>{task.task_type}</TableCell>
                        <TableCell>{task.status}</TableCell>
                        <TableCell>{task.remarks || '-'}</TableCell>
                        <TableCell>{dayjs(task.created_at).format('DD MMM YYYY, hh:mm A')}</TableCell>
                        <TableCell>{task.time_taken_secs ? `${task.time_taken_secs}s` : 'N/A'}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={backgroundTasks.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
