import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// sections
import { LoginForm } from '../sections/auth/login';
import ForgotPassword from '../sections/auth/login/ForgotPassword';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#F4F8FF',
}));

const StyledSection = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '50%',
  height: '100vh',
  [theme.breakpoints.up('md')]: {
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '60px 50px 60px 50px',
  backgroundColor: '#FFFFFF',
  borderRadius: theme.shape.borderRadius,
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '10px',
  [theme.breakpoints.down('md')]: {
    objectFit: 'contain',
  },
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <>
      <Helmet>
        <title> BillerBay Transmit | Login </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <StyledImage
              src="/assets/Login.png"
              alt="login"
              style={{
                objectFit: isWideScreen ? 'cover' : 'contain',
              }}
            />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            {forgotPassword ? (
              <ForgotPassword />
            ) : (
              <>
                <Stack direction={'row'} marginBottom={5} alignItems={'center'} justifyContent={'space-between'}>
                  <img src="/assets/transmit-logo.png" alt="login" style={{ width: '104px', height: '27px' }} />
                  <img
                    src="/assets/billerbay-logo.png"
                    alt="login"
                    style={{ width: '104px', height: '27px', marginTop: 10 }}
                  />
                </Stack>
                <Typography variant="h4" sx={{ mb: 3 }}>
                  Hi, Welcome Back
                </Typography>
                <Typography variant="h6" sx={{ mb: 3 }}>
                  Sign in
                </Typography>

                <LoginForm setForgotPassword={setForgotPassword} />
                <Typography variant="p" fontSize={'12px'} marginTop={5}>
                  By signing in, you agree to BillerBay’s Terms and Conditions & Privacy Policy
                </Typography>
                <Typography variant="p" fontSize={'12px'} marginTop={5} textAlign={'center'} color={'gray'}>
                  ©BillerBay 2024
                </Typography>
              </>
            )}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
