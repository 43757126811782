import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { useState, useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ReplayIcon from '@mui/icons-material/Replay';
import { toast } from 'react-toastify';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { formatCurrency } from '../utils/formatCurrency';
import { getClaimsDashboardInfo, getClaimsDashboardFilterValues } from '../services/api';

export default function DmrDashboard() {
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [claimsDashboardInfo, setClaimsDashboardInfo] = useState();
  const [allInsuranceTypes, setAllInsuranceTypes] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [allClaimsStatus, setAllClaimsStatus] = useState([]);
  const [allClaimPayers, setAllClaimPayers] = useState([]);
  const [selectedClaimStatus, setSelectedClaimStatus] = useState('');
  const [selectedInsuranceType, setSelectedInsuranceType] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedClaimPrimaryPayer, setSelectedClaimPrimaryPayer] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getDashboardInfo = async () => {
    setLoading(true);
    try {
      const response = await getClaimsDashboardInfo(
        startDate,
        endDate,
        selectedInsuranceType,
        selectedClaimPrimaryPayer,
        selectedDoctor,
        selectedClaimStatus
      );
      setClaimsDashboardInfo(response?.data?.info);
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
    setLoading(false);
  };

  const getFilterValues = async () => {
    setFilterLoading(true);
    try {
      const response = await getClaimsDashboardFilterValues();

      setAllInsuranceTypes(response?.data?.info?.insurance_types);
      setAllClaimsStatus(response?.data?.info?.claim_statuses);
      setAllClaimPayers(response?.data?.info?.claim_primary_payers);
      setAllDoctors(response?.data?.info?.doctor_names);
    } catch (error) {
      toast.error('Something went wrong');
      console.log(error);
    }
    setFilterLoading(false);
  };
  useEffect(() => {
    getFilterValues();
  }, []);

  useEffect(() => {
    getDashboardInfo();
  }, [startDate, endDate, selectedInsuranceType, selectedClaimStatus, selectedClaimPrimaryPayer, selectedDoctor]);

  const cardsData = [
    {
      id: 1,
      title: 'Total Number of Claims',
      description: claimsDashboardInfo?.count?.total_claims || 0,
      icon: <AccessibleForwardIcon />,
    },
    {
      id: 2,
      title: 'Total Charge Amount',
      description: formatCurrency(claimsDashboardInfo?.sum?.total_balance),
      icon: <TrendingUpIcon />,
    },
    {
      id: 3,
      title: 'Total Insurance Payments',
      description: formatCurrency(claimsDashboardInfo?.sum?.total_insurance_payments),
      icon: <AttachMoneyIcon />,
    },
    {
      id: 4,
      title: 'Total Patient Resp',
      description: formatCurrency(claimsDashboardInfo?.sum?.total_deductible),
      icon: <AccountBalanceWalletIcon />,
    },
    {
      id: 5,
      title: 'Total Patient Payments',
      description: formatCurrency(claimsDashboardInfo?.sum?.total_patient_payments),
      icon: <LocalAtmIcon />,
    },
  ];

  const handleStartDateChange = (newValue) => {
    const formattedStartDate = dayjs(newValue).format('YYYY-MM-DD');
    setStartDate(formattedStartDate);
    if (endDate && newValue && dayjs(endDate).isBefore(newValue)) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (newValue) => {
    const formattedEndDate = dayjs(newValue).format('YYYY-MM-DD');
    setEndDate(formattedEndDate);
  };

  const handleClaimStatusChange = (event) => {
    setSelectedClaimStatus(event.target.value);
  };

  const handleInsuranceTypeChange = (event) => {
    setSelectedInsuranceType(event.target.value);
  };

  const handleDoctorChange = (event) => {
    setSelectedDoctor(event.target.value);
  };

  const handlePayerNameChange = (event) => {
    setSelectedClaimPrimaryPayer(event.target.value);
  };

  const handleReset = () => {
    setSelectedDoctor('');
    setSelectedClaimStatus('');
    setSelectedInsuranceType('');
    setSelectedClaimPrimaryPayer('');
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <Helmet>
        <title>BillerBay Transmit | DMR Dashboard</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom>
              DMR Dashboard
            </Typography>
          </Box>

          <Box display={'flex'} mb={2} paddingX={2} gap={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ marginBottom: 2 }}
                label="Start Date"
                value={startDate ? dayjs(startDate) : null}
                maxDate={dayjs()}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />

              <DatePicker
                label="End Date"
                value={endDate ? dayjs(endDate) : null}
                minDate={startDate ? dayjs(startDate) : null}
                maxDate={dayjs()}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Stack direction={'row'} alignItems={'center'} gap={6} mb={2} paddingX={2}>
            <Box display="flex" sx={{ width: '100%' }} gap={2} alignItems={'center'}>
              <Box sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <InputLabel id="claim-status-label">Claim Status</InputLabel>
                  <Select
                    labelId="claim-status-label"
                    id="claim-status-select"
                    value={selectedClaimStatus}
                    label="Claim Status"
                    onChange={handleClaimStatusChange}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 300 } },
                    }}
                  >
                    {filterLoading ? (
                      <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={20} />
                          <Typography sx={{ marginLeft: 1 }}>Loading...</Typography>
                        </Box>
                      </MenuItem>
                    ) : (
                      allClaimsStatus.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <InputLabel id="insurance-type-label">Insurance Type</InputLabel>
                  <Select
                    labelId="insurance-type-label"
                    id="insurance-type-select"
                    value={selectedInsuranceType}
                    label="Insurance Type"
                    onChange={handleInsuranceTypeChange}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 300 } },
                    }}
                  >
                    {filterLoading ? (
                      <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={20} />
                          <Typography sx={{ marginLeft: 1 }}>Loading...</Typography>
                        </Box>
                      </MenuItem>
                    ) : (
                      allInsuranceTypes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <InputLabel id="doctor-label">Doctor</InputLabel>
                  <Select
                    labelId="doctor-label"
                    id="doctor-select"
                    value={selectedDoctor}
                    label="Doctor"
                    onChange={handleDoctorChange}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 300 } },
                    }}
                  >
                    {filterLoading ? (
                      <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={20} />
                          <Typography sx={{ marginLeft: 1 }}>Loading...</Typography>
                        </Box>
                      </MenuItem>
                    ) : (
                      allDoctors.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <InputLabel id="payer-name-label">Payer Name</InputLabel>
                  <Select
                    labelId="payer-name-label"
                    id="payer-name-select"
                    value={selectedClaimPrimaryPayer}
                    label="Practice Name"
                    onChange={handlePayerNameChange}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: 300 } },
                    }}
                  >
                    {filterLoading ? (
                      <MenuItem disabled>
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={20} />
                          <Typography sx={{ marginLeft: 1 }}>Loading...</Typography>
                        </Box>
                      </MenuItem>
                    ) : (
                      allClaimPayers.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>

              <Button variant="text" startIcon={<ReplayIcon />} onClick={handleReset}>
                Reset
              </Button>
            </Box>
          </Stack>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={2}>
                {cardsData.map((card) => (
                  <Grid item xs={12} sm={6} md={4} key={card.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5">{card.title}</Typography>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                          {card.icon}
                          <Typography variant="body1">{card.description}</Typography>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Payers
                  </Typography>
                  <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Practice Name</TableCell>
                          <TableCell>Claim Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {claimsDashboardInfo?.count?.claim_primary_payer_name_count?.length > 0 ? (
                          claimsDashboardInfo.count.claim_primary_payer_name_count.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row?.payer_name}</TableCell>
                              <TableCell>{row?.claim_count}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2}>No data available</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Claim Status
                      </Typography>
                      <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Claim Status</TableCell>
                              <TableCell>Claim Count</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {claimsDashboardInfo?.count?.claim_status_count?.length > 0 ? (
                              claimsDashboardInfo.count.claim_status_count.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{row?.claim_status}</TableCell>
                                  <TableCell>{row?.claim_count}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={2}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Insurance Type
                      </Typography>
                      <TableContainer style={{ maxHeight: 300, overflowY: 'auto' }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Insurance Type</TableCell>
                              <TableCell>Claim Count</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {claimsDashboardInfo?.count?.insurance_type_count?.length > 0 ? (
                              claimsDashboardInfo.count.insurance_type_count.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{row?.insurance_type}</TableCell>
                                  <TableCell>{row?.claim_count}</TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={2}>No data available</TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Typography paddingY={2} paddingX={4} variant="body2" color="textSecondary">
              Note: Values less than 10 are not displayed.
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
}
